.has-navigation-dropdown .navigation-wrapper.ativo {
  display: block;
  border-top: 2px solid #ffcd07;
  -webkit-box-shadow: 0 8px 6px -6px gray;
  box-shadow: 0 8px 6px -6px gray;
}

.has-navigation-dropdown .navigation-wrapper {
  position: fixed;
  z-index: 5;
  right: 0;
  left: 0;
  display: none;
  max-height: calc(100vh - 144px);
  background-color: #ededed;
  overflow-y: auto;
}

.menu-listitem.sub {
  background-color: #f6f6f6;
}

.menu-listitem.sub .menu-folder.drop-menu .menu-item {
  border-bottom: none;
}

.menu-listitem:not(.sub) .menu-item {
  border-bottom: 1px solid var(--menu-divider);
}

.support {
  display: flex;
  align-items: center;
  text-align: center;
}

.menu-panel_active {
  min-height: calc(70vh);
  /*-webkit-animation: conditionalOpen 0.4s normal forwards ease-in-out;*/
  /*-moz-animation: conditionalOpen 0.4s normal forwards ease-in-out;*/
  /*animation: conditionalOpen 0.4s normal forwards ease-in-out;*/
  /*-webkit-transform-origin: 0% 50%;*/
  /*-moz-transform-origin: 0% 50%;*/
  /*transform-origin: 0% 50%;*/
}

.menu-panel_hidden {
  top: 0;
  display: none !important;
  height: 0px !important;
  /*-webkit-animation: conditionalClose 0.4s normal forwards ease-in-out !important;*/
  /*-moz-animation: conditionalClose 0.4s normal forwards ease-in-out !important;*/
  /*animation: conditionalClose 0.4s normal forwards ease-in-out !important;*/
  /*-webkit-transform-origin: 100% 50%;*/
  /*-moz-transform-origin: 100% 50%;*/
  /*transform-origin: 100% 50%;*/
}

/*@-webkit-keyframes conditionalOpen {*/
/*  from {*/
/*    transform: scaleX(0);*/
/*  }*/

/*  to {*/
/*    transform: scaleX(1);*/
/*  }*/
/*}*/
/*@-moz-keyframes conditionalOpen {*/
/*  from {*/
/*    transform: scaleX(0);*/
/*  }*/

/*  to {*/
/*    transform: scaleX(1);*/
/*  }*/
/*}*/
/*@keyframes conditionalOpen {*/
/*  from {*/
/*    transform: scaleX(0);*/
/*  }*/

/*  to {*/
/*    transform: scaleX(1);*/
/*  }*/
/*}*/

/*@-webkit-keyframes conditionalClose {*/
/*  from {*/
/*    transform: scaleX(1);*/
/*  }*/

/*  to {*/
/*    transform: scaleX(0);*/
/*  }*/
/*}*/
/*@-moz-keyframes conditionalClose {*/
/*  from {*/
/*    transform: scaleX(1);*/
/*  }*/

/*  to {*/
/*    transform: scaleX(0);*/
/*  }*/
/*}*/
/*@keyframes conditionalClose {*/
/*  from {*/
/*    transform: scaleX(1);*/
/*  }*/

/*  to {*/
/*    transform: scaleX(0);*/
/*  }*/
/*}*/
