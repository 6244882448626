.rc-time-picker-panel {
  width: 1px;
  height: 1px;
}

.rc-time-picker-panel-inner {
  margin-top: -30px;
}

.rc-time-picker-input {
  margin-top: -100px;
}
