.br-avatar {
  --avatar-size: var(--avatar-small);
  --avatar-icon-size: var(--avatar-icon-small);
  --avatar-text-size: var(--avatar-text-small);
  --avatar-small: 40px;
  --avatar-medium: 100px;
  --avatar-large: 160px;
  --avatar-icon-small: var(--icon-size-2x);
  --avatar-icon-medium: var(--icon-size-5x);
  --avatar-icon-large: var(--icon-size-8x);
  --avatar-text-small: var(--font-size-scale-up-03);
  --avatar-text-medium: var(--font-size-scale-up-07);
  --avatar-text-large: var(--font-size-scale-up-11);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  font-size: var(--avatar-text-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--avatar-size);
  vertical-align: middle;
}

.br-avatar .content {
  display: inline-flex;
  overflow: hidden;
  width: var(--avatar-size);
  height: var(--avatar-size);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--blue-10);
  color: var(--blue-warm-20);
  font-size: var(--avatar-text-size);
  text-align: center;
  text-transform: uppercase;
  vertical-align: inherit;
}

.br-avatar .content img {
  width: var(--avatar-size);
  height: var(--avatar-size);
  vertical-align: inherit;
}

.br-avatar .content .svg-inline--fa,
.br-avatar .content .fa,
.br-avatar .content .fab,
.br-avatar .content .fad,
.br-avatar .content .fal,
.br-avatar .content .far,
.br-avatar .content .fas {
  --icon-size: var(--avatar-icon-size);
  margin-top: 0.25em;
}

.br-avatar.is-small,
.br-avatar.small,
.br-avatar[small] {
  --avatar-size: var(--avatar-small);
  --avatar-icon-size: var(--avatar-icon-small);
  --avatar-text-size: var(--avatar-text-small);
}

.br-avatar.is-medium,
.br-avatar.medium,
.br-avatar[medium] {
  --avatar-size: var(--avatar-medium);
  --avatar-icon-size: var(--avatar-icon-medium);
  --avatar-text-size: var(--avatar-text-medium);
}

.br-avatar.is-large,
.br-avatar.large,
.br-avatar[large] {
  --avatar-size: var(--avatar-large);
  --avatar-icon-size: var(--avatar-icon-large);
  --avatar-text-size: var(--avatar-text-large);
}

.br-avatar-action {
  border: 0;
  background: transparent;
}

.br-avatar-action:not(:disabled):hover {
  background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
}

.br-avatar-action:focus {
  outline: none;
}

.br-avatar-action.focus-visible,
.br-avatar-action:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}

.br-avatar[data-toggle='dropdown']:focus {
  outline: none;
}

.br-avatar[data-toggle='dropdown'].focus-visible,
.br-avatar[data-toggle='dropdown']:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}

.br-avatar .image,
.br-avatar .letter {
  display: inline-flex;
  overflow: hidden;
  width: var(--avatar-size);
  height: var(--avatar-size);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--blue-10);
  color: var(--blue-warm-20);
  font-size: var(--avatar-text-size);
  text-align: center;
  text-transform: uppercase;
  vertical-align: inherit;
}

.br-avatar .image img,
.br-avatar .letter img {
  width: var(--avatar-size);
  height: var(--avatar-size);
  vertical-align: inherit;
}

.br-avatar .image .svg-inline--fa,
.br-avatar .image .fa,
.br-avatar .image .fab,
.br-avatar .image .fad,
.br-avatar .image .fal,
.br-avatar .image .far,
.br-avatar .image .fas,
.br-avatar .letter .svg-inline--fa,
.br-avatar .letter .fa,
.br-avatar .letter .fab,
.br-avatar .letter .fad,
.br-avatar .letter .fal,
.br-avatar .letter .far,
.br-avatar .letter .fas {
  --icon-size: var(--avatar-icon-size);
  margin-top: 0.25em;
}
