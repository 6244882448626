.contact-page {
  /* h1 */
  margin-bottom: 8px;
  /* identical to box height, or 115% */

  /* Gray/.gray-80 */
  color: #333333;
  /* Text styles/Título 01 */
  font-family: 'Rawline';
  font-size: 34.84px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.contact-title {
  /* or 115% */

  color: #000000;
  /* Reitoria UFT */

  /* Text styles/Título 03 */
  font-family: 'Rawline';
  font-size: 50.16px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
}

.contact-update {
  margin-top: 24px;
}

.container-box {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 24px;
}

.container-column {
  width: 20%;
  flex: 1 1 auto;
  margin: 0 30px 0 0;

  font-size: 16px;
  line-height: 23px;
}

.container-column p {
  margin-bottom: 2px;
}

.container-column-sm {
  width: 15%;
  flex: 1 1 auto;
  margin: 0 30px 0 10px;
}

.container-map {
  width: 100%;
  margin-top: 24px;
}

.title-map {
  margin-bottom: 16px;
  /* identical to box height, or 115% */

  color: #000000;
  /* Text styles/Subtítulo 01 */

  font-family: 'Rawline';
  font-size: 16.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.open-date {
  width: 40px;
  margin-bottom: 0px;
  font-weight: 600;
}

.open-hour {
  flex-basis: 30%;
  flex-grow: 1;
  align-items: flex-start;
}

.ui.table {
  border-collapse: collapse !important;
}

.table thead th {
  border: hidden !important;
  background-color: #f0f0f0 !important;
  color: #1351b4 !important;
  text-align: center;
}

.table tfoot th {
  border: hidden !important;
  background-color: #ffffff !important;
}

.table td {
  border-right: hidden !important;
  border-left: hidden !important;
  font-size: 16px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.status {
  z-index: 1;
}

.rdg-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.service-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-sub-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 5px;
}

.service-header-title {
  color: #1351b4;
  font-family: 'Rawline';
  font-size: 34.84px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.service-sub-header-title {
  color: #333333;
  font-family: 'Rawline';
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  font-size: 34.84px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  text-align: left !important;
}

.service-sub-header-subtitle {
  padding-bottom: 55px;
  border-color: #e6e6e6;
  margin-top: 15px;
  color: #757575;
  font-family: 'Rawline';
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.service-info {
  height: 40px;
  padding-top: 10px !important;
  border-top: 1px solid;
  margin-bottom: 73px;
  color: #a9aeb1;
}

.service-author {
  float: left;
  font-family: 'Rawline';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.service-share {
  float: right !important;
}

.service-icon {
  color: #1351b4;
}

.service-accordeon-title {
  color: #000000 !important;
  font-family: 'Rawline' !important;
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}

.service-accordeon-text {
  max-width: 769px;
  margin-left: 40px;
  color: #757575;
  font-family: 'Rawline';
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.service-accordeon:nth-last-child(1) {
  border-bottom: 0px !important;
}

.whatsapp-button {
  z-index: 2;
}

.whatsapp-button > div > span {
  line-height: 0.6em;
}

.whatsapp-button div header span {
  line-height: 1.3em;
}

@media screen and (max-width: 520px) {
  .whatsapp-button div header span {
    overflow: hidden;
    line-height: 1.2285em;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
}

.whatsapp-button div::after {
  animation: none;
}

.btn-whatsapp {
  width: 100%;
  min-width: 140px;
  border: 1px solid #6fcf97 !important;
  border-radius: 20px;
  background-color: #27ae60 !important;
  color: #ffffff !important;
}

.service-workflow_image {
  margin-left: 60px !important;
}

.service-button {
  float: right;
}

.service-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.service-content-right {
  width: 35%;
}

.related-service-link {
  display: flex;
  flex-direction: row;
}

.related-service-icon {
  display: flex !important;
  width: 15%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.related-service-text {
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: flex-start;
}

.related-services-title {
  color: #757575 !important;
  font-family: Rawline;
  font-size: 18px;
  font-weight: 700 !important;
  letter-spacing: 0em;
  line-height: 23px;
  text-align: left;
}

.related-service {
  padding: 12px 24px 12px 4px;
  border-bottom: 1px #cccccc solid;
  background-color: #f6f6f6;
}

.related-service a {
  color: #0b4778 !important;
  font-size: 18px;
  font-weight: 600 !important;
}

.related-service a i {
  color: #cccccc !important;
}
