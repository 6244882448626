.card-service {
  width: 90%;
  height: 250px;
  padding: 5px 5px 5px 5px !important;
  margin: 0 30px 20px 0 !important;
  /*margin-right: 100px !important;*/
  background-color: #fcfcfc;
  box-shadow: 0 1px 6px 0 rgba(51, 51, 51, 0.16);
}

.card-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

.icon-service {
  width: 100%;
  color: #0b4778;
  text-align: center;
}

.icon-service i {
  width: 100%;
  font-size: 6em;
}

.title-service {
  color: #0b4778;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.card-service:hover {
  box-shadow: 1px 8px 20px grey;
  /*-webkit-transition: box-shadow 0.6s ease-in;*/
}
