.ui.buttons > .button {
  background: none !important;
  color: #68778d !important;
}

.three.wide.field > button {
  padding: 10px !important;
}

.field > input {
  padding-left: 5px !important;
}
