.share-button {
  margin-right: 5px;
}

.share-first {
  margin-left: 5px;
}

.share-comp {
  top: 0;
  display: flex;
}
